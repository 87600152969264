import dayjs from 'dayjs';
import nookies from 'nookies';

export const CHAT_TOOLTIP_COOKIE = 'chat-tooltip';

const useChatCookies = () => {
  const getCookieDomain = () => {
    const url = window.location.href;

    if (url.includes('linkareer')) return '.linkareer.com';
    if (url.includes('lnkr')) return '.lnkr.cc';
    return 'localhost';
  };

  const setChatCookie = (cookie: string, expires: Date) => {
    nookies.set(null, cookie, Date.now().toString(), {
      expires,
      domain: getCookieDomain(),
      path: '/',
    });
  };

  const addChatTooltipCookie = () => {
    const oneDay = dayjs().add(7, 'day').toDate();

    setChatCookie(CHAT_TOOLTIP_COOKIE, oneDay);
  };

  return {
    addChatTooltipCookie,
  };
};

export default useChatCookies;
