import React from 'react';
import styled from 'styled-components';
import Link from 'next/link';
import Logo from 'app.component/logo/Logo';
import UserProfile from 'app.feature/academy/component/user/UserProfile';
import { useRecoilValue } from 'recoil';
import { projectConfigState } from 'recoil/Academy/projectConfigState';
import { useCurrentUser } from 'src/app.hooks/useCurrentUser';

const Header: React.FC = () => {
  const { user, isLoading } = useCurrentUser();
  const { loginPageUrl, logoutRedirectUri } = useRecoilValue(projectConfigState);
  return (
    <StyledWrapper>
      <Logo />
      {user ? (
        <div className="login-box">
          {user && <UserProfile nickname={user.nickname} />}
          <Link href={logoutRedirectUri} className="user-btn">
            로그아웃
          </Link>
        </div>
      ) : (
        <Link href={loginPageUrl} className="user-btn">
          로그인
        </Link>
      )}
    </StyledWrapper>
  );
};

export default Header;

const StyledWrapper: any = styled.header`
  padding: 20px 40px;
  border-bottom: 1px solid #f5f5f5;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  .login-box {
    display: flex;
    align-items: center;
  }
  .user-btn {
    margin-left: 20px;
    cursor: pointer;
    padding: 10px;
    border: 1px solid #01a0ff;
    border-radius: 5px;
    color: #01a0ff;
  }
`;
