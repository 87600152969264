import { axiosClient } from './axios';

const UserApi = {
  currentUser: async () => {
    try {
      const response = await axiosClient.get('/apiV2/auth/profile');
      if (response.status === 401) return null;
      return response.data;
    } catch (error) {
      //console.log(error);
      return null;
    }
    return '';
  },
  // profile: async (userId: string) => {
  //   try {
  //     const response = await axiosClient.get(`/apiV2/auth/profile/${userId}`);
  //     return response.data;
  //   } catch (error) {}
  //   return '';
  // },
};

export default UserApi;
