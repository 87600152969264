import Image from 'next/image';
import { FC } from 'react';
import styled from 'styled-components';
import useChatCookies from '../../../chat/hook/useChatCookies';

interface IFProps {
  onClose: () => void;
}

const HeaderChatTooltipDesktop: FC<IFProps> = ({ onClose }) => {
  const { addChatTooltipCookie } = useChatCookies();

  const handleClickChatTooltip = () => {
    addChatTooltipCookie();
    onClose();
  };

  return (
    <StyledWrapper onClick={handleClickChatTooltip}>
      <div className="text">채팅방에서 실시간으로 채용 정보를 공유해보세요!</div>
      <Image src="/images/linkareer/ic-close-white.svg" alt="닫기" width={12} height={12} />
    </StyledWrapper>
  );
};

export default HeaderChatTooltipDesktop;

const StyledWrapper = styled.div`
  position: absolute;
  bottom: -66px;
  right: -140px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 330px;
  padding: 11px 15px;
  border-radius: 8px;
  background-color: #003056;
  z-index: 5;
  cursor: pointer;
  ::after {
    content: '';
    position: absolute;
    top: -20px;
    left: 50%;
    border: 10px solid transparent;
    border-bottom: 10px solid #003056;
    transform: translateX(-50%);
    z-index: 1;
  }
  ::before {
    content: '';
    position: absolute;
    top: -20px;
    left: 50%;
    border: 10px solid transparent;
    border-bottom: 10px solid #003056;
    transform: translateX(-50%);
    z-index: 0;
  }
  .text {
    font-size: 14px;
    line-height: 2;
    letter-spacing: -0.56px;
    user-select: none;
    color: #fff;
  }
`;
