import { useQuery } from '@tanstack/react-query';
import { linkareerGqlRequest } from '../module/linkareerGqlRequest';
import { gqlCoverLetterCountQuery } from '../gql/linkareer.graphql';
import { CoverLetterCountQueryResult } from '../types/gql.types';
import { formatNumberComma } from '../module/utils';

export const useCoverLetterCount = () => {
  const requestCoverLetterCount = async () => {
    return await linkareerGqlRequest<CoverLetterCountQueryResult>({
      query: gqlCoverLetterCountQuery,
    });
  };

  const { data } = useQuery(['linkareerCoverLetterCount'], async () => requestCoverLetterCount(), {
    suspense: false,
  });

  const coverLetterCount = formatNumberComma(data?.coverLetterTotalCount || 12469);

  return { coverLetterCount };
};
