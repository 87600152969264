import Link from 'next/link';
import { FC } from 'react';
import BookMarkBorderIcon from 'src/app.core/linkareer/svgIcon/BookMarkBorderIcon';
import styled from 'styled-components';
import NotificationBadge from './NotificationBadge';
import { LINKAREER_DOMAIN } from 'src/app.core/linkareer/constant/urls';

interface IFHeaderScrapIconProps {
  scrapCount?: number;
}

const HeaderScrapIcon: FC<IFHeaderScrapIconProps> = ({ scrapCount }) => {
  return (
    <StyledWrapper>
      <Link href={`https://${LINKAREER_DOMAIN}/accounts/scrap`} className="icon-button">
        <BookMarkBorderIcon className="bookmark-icon" />
      </Link>
      <NotificationBadge count={scrapCount} />
    </StyledWrapper>
  );
};

export default HeaderScrapIcon;

const StyledWrapper = styled.div`
  width: 20px;
  height: 20px;
  padding: 8px;
  position: relative;

  .icon-button {
    display: flex;
    align-items: center;

    .bookmark-icon {
      width: 20px;
      height: 20px;
      & g,
      & path {
        stroke: #fff !important;
      }
    }
  }
`;
