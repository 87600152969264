import Image from 'next/image';

import getErrorMessage from 'utils/etc/getErrorMessage';

import styles from './styles.module.scss';

interface Props<ErrorType extends Error = Error> {
  error: ErrorType;
  reset: () => void;
}

export default function ErrorHelper({ error, reset }: Props) {
  const canShowErrorMessage =
    process.env.CBT_ENV === 'development' ||
    process.env.NEXT_PUBLIC_RUN_ENVIRONMENT === 'development';

  return (
    <div className={styles.errorBoundaryRoot}>
      <Image
        alt="error"
        src="https://s3.ap-northeast-2.amazonaws.com/www.easyelectric.co.kr/front/errorImg.png"
        width={1000}
        height={1000}
      />
      <div className={styles.title}>오류가 발생하였습니다</div>
      <div className={styles.content}>
        <div>페이지 새로고침으로 해결되지 않는다면,</div>
        <div>우측 하단 채널톡을 통해 문의하시기 바랍니다</div>
      </div>
      {canShowErrorMessage && (
        <div className={styles.content_error}>
          {getErrorMessage(error)}
          <br />
          <span>디버깅용</span>
        </div>
      )}
      <button onClick={reset} className={styles.refreshBtn}>
        새로고침
      </button>
    </div>
  );
}
