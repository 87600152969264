import { Fragment, useEffect, useState } from 'react';
import { uaIsMobile } from '../../module/uaIsMobile';
import FooterMobile from './mobile/Footer.mobile';
import FooterDesktop from './desktop/Footer.desktop';
import { SnackbarProvider } from 'notistack';
import CustomerMessageFormContainer from '../userCustomerService/CustomerMessageForm';

const FooterLinkareer = () => {
  const [isMobile, setIsMobile] = useState<boolean>(false);

  useEffect(() => {
    if (navigator) {
      const uaMobile = uaIsMobile(navigator.userAgent);
      setIsMobile(() => uaMobile);
    }
  }, []);

  return (
    <SnackbarProvider maxSnack={3} autoHideDuration={2000}>
      <CustomerMessageFormContainer>
        {isMobile ? <FooterMobile /> : <FooterDesktop />}
      </CustomerMessageFormContainer>
    </SnackbarProvider>
  );
};

export default FooterLinkareer;
