import { FC } from 'react';
import styled from 'styled-components';
import FooterAppInstall from './component/FooterAppInstall';
import FooterCompanyInfo from './component/FooterCompanyInfo';
import FooterMenuList from './component/FooterMenuList';

const FooterDesktop: FC = () => {
  return (
    <StyledWrapper>
      <div className="container">
        <div className="flex-box">
          <FooterMenuList />
          <FooterAppInstall />
        </div>
        <FooterCompanyInfo />
      </div>
    </StyledWrapper>
  );
};

export default FooterDesktop;

const StyledWrapper = styled.footer`
  width: 100%;
  border-top: 1px solid #ddd;

  .container {
    max-width: 1148px;
    padding: 30px 24px;
    margin: 0 auto;
    box-sizing: border-box;

    .flex-box {
      display: flex;
      gap: 100px;
    }
  }
`;
