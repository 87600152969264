import { FC } from 'react';
import Link from 'next/link';
import styled from 'styled-components';
import { LINKAREER_DOMAIN } from 'src/app.core/linkareer/constant/urls';
import { useOpenCustomerMessageForm } from '../../userCustomerService/CustomerMessageForm';

const FooterMobile: FC = () => {
  const openCustomerMessageForm = useOpenCustomerMessageForm();
  return (
    <StyledWrapper>
      <div className="flex-box">
        <Link href={`https://${LINKAREER_DOMAIN}/terms`}>이용약관</Link>
        <Link href={`https://${LINKAREER_DOMAIN}/privacy`}>개인정보처리방침</Link>
        <p onClick={openCustomerMessageForm}>고객문의</p>
      </div>
      <div className="copyright">
        Copyright © <b>Linkareer Inc</b>. All Rights Reserved.
      </div>
    </StyledWrapper>
  );
};

export default FooterMobile;

const StyledWrapper = styled.footer`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  padding: 15px 0 69px;
  background-color: #f4f4f4;

  .flex-box {
    display: flex;
    justify-content: center;
    gap: 40px;
    a,
    p {
      font-size: 12px;
      font-weight: 500;
      line-height: 1.43;
      margin: 0;
      padding: 0;
    }
  }
  .copyright {
    font-size: 12px;
  }
`;
