import { useRouter } from 'next/router';
import {
  ComponentProps,
  createContext,
  FC,
  ReactNode,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { LINKAREER_CHAT_URL } from 'src/app.core/linkareer/constant/urls';
import { useCurrentUser } from 'src/app.core/linkareer/hook/useCurrentUser';
import styled from 'styled-components';
import ChattingIframeSkeleton from '../component/ChattingIframeSkeleton';
import useChattingLazyLoad from '../hook/useChattingLazyLoad';
import useChattingRequestMessageHandler from '../hook/useChattingRequestMessageHandler';
import useParseChatQueryEffect from '../hook/useParseChatQueryEffect';
import ShareModal from '../ShareModal';
import { TChattingQueryParamKeys } from '../type/chattingQueryParams';
import { TChattingReportTypes } from '../type/chattingReport.type';
import chattingQueryParamsGenerator, {
  TQueryParamsGeneratorOption,
} from './chattingQueryParamsGenerator';

type TChattingQueryParamOption = TQueryParamsGeneratorOption<TChattingQueryParamKeys>;

interface IFProps {
  children: ReactNode;
}

interface IFChattingContextProps {
  isOpen: boolean;
  isLoaded: boolean;
  openChatting: (reportOption?: TChattingReportTypes) => void;
  closeChatting: () => void;
  toggleChatting: () => void;
  reportToChatting: (reportOption: TChattingReportTypes) => void;
}

const updateQueryParams = (
  prevOptions: TChattingQueryParamOption[],
  updateOptions: TChattingQueryParamOption[]
): TChattingQueryParamOption[] => {
  const updatedMap = new Map<string, TChattingQueryParamOption>();

  prevOptions.forEach((option) => {
    updatedMap.set(option.paramKey, option);
  });
  updateOptions.forEach((option) => {
    updatedMap.set(option.paramKey, option);
  });

  return Array.from(updatedMap.values());
};

const ChattingContext = createContext<IFChattingContextProps | undefined>(undefined);

export const useChattingContext = () => {
  const context = useContext(ChattingContext);
  if (!context) {
    throw new Error('ChattingContext must be used within a ChattingProvider');
  }

  return context;
};

type TShareModalProps = {
  open: boolean;
  quote: string;
  shareLink: string;
};

export const ChattingProvider: FC<IFProps> = ({ children }) => {
  const router = useRouter();
  const iframeRef = useRef<HTMLIFrameElement>(null);

  const [isShouldLoad, setIsShouldLoad] = useState<boolean>(false);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [queryParam, setQueryParam] = useState<TChattingQueryParamOption[]>([]);

  const [shareModalProps, setShareModalProps] = useState<TShareModalProps>({
    open: false,
    quote: '링커리어 채팅방 공유',
    shareLink: 'https://www.linkareer.com/',
  });

  const { currentUser } = useCurrentUser();

  const iframeUrl = `${LINKAREER_CHAT_URL}?${chattingQueryParamsGenerator(queryParam)}`;

  const checkSureChattingLoad = () => {
    if (!isLoaded && !isShouldLoad) {
      setIsShouldLoad(true);
    }
  };

  const handleReportToChatting = (reportOption: TChattingReportTypes) => {
    if (typeof window === 'undefined') {
      return;
    }
    iframeRef.current?.contentWindow?.postMessage(reportOption, {
      targetOrigin: LINKAREER_CHAT_URL,
    });
  };

  const openChatting = (reportOption?: TChattingReportTypes) => {
    checkSureChattingLoad();
    if (reportOption) {
      handleReportToChatting(reportOption);
    }
    setIsOpen(true);
  };

  const closeChatting = () => {
    setIsOpen(false);
  };

  const toggleChatting = () => {
    isOpen ? closeChatting() : openChatting();
  };

  useParseChatQueryEffect(router, isLoaded, openChatting);
  useChattingLazyLoad(() => setIsShouldLoad(true));
  useChattingRequestMessageHandler({
    accessToken: currentUser?.tokenSet?.accessToken,
    onRequestClose: () => {
      closeChatting();
    },
    onRequestSnsShare: (url) => {
      setShareModalProps((prev) => ({
        ...prev,
        open: true,
        shareLink: url,
      }));
    },
  });

  useEffect(() => {
    if (currentUser) {
      const accessToken = currentUser?.tokenSet?.accessToken;
      if (!accessToken) {
        return;
      }

      setQueryParam((prev) =>
        updateQueryParams(prev, [
          {
            paramKey: 'accessToken',
            paramValue: accessToken,
          },
        ])
      );
      checkSureChattingLoad();
    }
  }, []);

  return (
    <ChattingContext.Provider
      value={{
        isOpen,
        isLoaded,
        openChatting,
        closeChatting,
        toggleChatting,
        reportToChatting: handleReportToChatting,
      }}
    >
      {children}
      <StyledChattingIframeWrapper data-is-open={isOpen} data-is-loaded={isLoaded}>
        <section>
          <ChattingIframeSkeleton className="iframe-skeleton" />
          {isShouldLoad && (
            <iframe
              ref={iframeRef}
              src={iframeUrl}
              onLoad={() => setIsLoaded(true)}
              loading="lazy"
              allow="clipboard-read; clipboard-write"
              title="linkareer chatting iframe"
            />
          )}
        </section>
      </StyledChattingIframeWrapper>
      {shareModalProps.open && (
        <ShareModal
          {...shareModalProps}
          onClose={() =>
            setShareModalProps((prev) => ({
              ...prev,
              open: false,
            }))
          }
        />
      )}
    </ChattingContext.Provider>
  );
};

const StyledChattingIframeWrapper = styled.div`
  position: fixed;
  transition: right 0.3s ease-in-out;
  top: 0;
  right: -370px;
  z-index: 9999;

  > section {
    position: relative;
    background-color: white;
    border-left: 1px solid #e8e8e8;

    > iframe {
      width: 360px;
      height: 100vh;
      border: 0;
    }

    > .iframe-skeleton {
      position: absolute;
      top: 0;
      left: 0;
      width: 360px;
      height: 100vh;
    }
  }

  &[data-is-open='true'] {
    right: 0;
  }

  &[data-is-loaded='true'] {
    .iframe-skeleton {
      display: none;
    }
  }
`;
