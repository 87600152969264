import { FC } from 'react';
import TSvgProps from '../types/svgPropsType';

interface CommunityIconProps extends TSvgProps {
  isActive: boolean;
}

const CommunityIcon: FC<CommunityIconProps> = ({ isActive, ...props }) => {
  return isActive ? (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24.49"
      height="24"
      viewBox="0 0 24.49 24"
      {...props}
    >
      <desc>커뮤니티 아이콘 활성화</desc>
      <g data-name="icon / 24 / commu">
        <path fill="none" d="M.244 0h24v24h-24z" data-name="사각형 29546"></path>
        <g data-name="그룹 43567">
          <g strokeWidth="1.5" data-name="그룹 43568">
            <g fill="#333" stroke="#333" data-name="그룹 43571">
              <path
                d="M7.084 9.835a2.427 2.427 0 11-2.427-2.36 2.394 2.394 0 012.426 2.36"
                data-name="패스 15873"
              ></path>
              <path
                strokeLinejoin="round"
                d="M8.258 15.018a3.911 3.911 0 00-3.6-2.319 3.855 3.855 0 00-3.907 3.8v2.194h7.131"
                data-name="패스 15877"
              ></path>
            </g>
            <g fill="#333" stroke="#333" data-name="그룹 43572">
              <path
                d="M22.258 9.835a2.427 2.427 0 11-2.426-2.36 2.394 2.394 0 012.426 2.36"
                data-name="패스 15875"
              ></path>
              <path
                strokeLinejoin="round"
                d="M16.233 18.693h7.507v-2.194a3.855 3.855 0 00-3.907-3.8 3.912 3.912 0 00-3.6 2.319"
                data-name="패스 15879"
              ></path>
            </g>
            <path
              fill="none"
              stroke="#fff"
              strokeLinejoin="round"
              d="M17.346 13.468a6.6 6.6 0 00-6.074-4.184 6.729 6.729 0 00-6.593 6.856v3.958h10.535"
              data-name="패스 15909"
            ></path>
            <path
              fill="none"
              stroke="#fff"
              strokeLinejoin="round"
              d="M8.587 20.098h11.006v-3.873a6.761 6.761 0 00-12.974-2.614"
              data-name="패스 15910"
            ></path>
            <g fill="#333" stroke="#333" data-name="그룹 43616">
              <path
                strokeLinejoin="round"
                d="M16.566 12.087a6.241 6.241 0 00-8.819 0 4.594 4.594 0 00-1.726 3.562v4.026h12.272v-4.027a4.6 4.6 0 00-1.727-3.562"
                data-name="패스 15887"
              ></path>
              <path
                d="M15.965 5.757a3.81 3.81 0 11-3.808-3.7 3.757 3.757 0 013.808 3.7"
                data-name="패스 15888"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  ) : (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24.695"
      height="24"
      viewBox="0 0 24.695 24"
      {...props}
    >
      <desc>커뮤니티 아이콘 비활성화</desc>
      <g data-name="icon / 24 / commu">
        <path fill="none" d="M.346 0h24v24h-24z" data-name="사각형 29546"></path>
        <g data-name="그룹 43567">
          <g data-name="그룹 43568">
            <g fill="#fff" stroke="#888" strokeWidth="1.4" data-name="그룹 43571">
              <path
                d="M7.032 9.835a2.427 2.427 0 11-2.427-2.36 2.394 2.394 0 012.426 2.36"
                data-name="패스 15873"
              ></path>
              <path
                strokeLinejoin="round"
                d="M8.206 15.018a3.911 3.911 0 00-3.6-2.319 3.855 3.855 0 00-3.907 3.8v2.194H7.83"
                data-name="패스 15877"
              ></path>
            </g>
            <g fill="#fff" stroke="#888" strokeWidth="1.4" data-name="그룹 43572">
              <path
                d="M22.513 9.835a2.427 2.427 0 11-2.426-2.36 2.394 2.394 0 012.426 2.36"
                data-name="패스 15875"
              ></path>
              <path
                strokeLinejoin="round"
                d="M16.488 18.693h7.507v-2.194a3.855 3.855 0 00-3.907-3.8 3.912 3.912 0 00-3.6 2.319"
                data-name="패스 15879"
              ></path>
            </g>
            <g data-name="그룹 43569">
              <g fill="#fff" stroke="#888" strokeWidth="1.5" data-name="그룹 43570">
                <path
                  strokeLinejoin="round"
                  d="M16.822 12.087a6.241 6.241 0 00-8.819 0 4.594 4.594 0 00-1.726 3.562v4.026h12.272v-4.027a4.6 4.6 0 00-1.727-3.562"
                  data-name="패스 15887"
                ></path>
                <path
                  d="M16.221 5.757a3.81 3.81 0 11-3.808-3.7 3.757 3.757 0 013.808 3.7"
                  data-name="패스 15888"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default CommunityIcon;
