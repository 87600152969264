import { ProjectConfig } from 'app.core/module/projectConfigs';
import { default as localHeader } from 'app.core/local/component/Header';
import { default as localFooter } from 'app.core/local/component/Footer';

export const LocalProject: { [key in 'production' | 'development' | 'test']: ProjectConfig } = {
  development: {
    projectName: 'local',
    idp: 'local',
    clientBaseUrl: 'https://www.local-cbt.link/',
    loginPageUrl: 'https://local-cbt.link/login',
    logoutRedirectUri: 'https://local-cbt.link/api/auth/logout',
    apiBaseUrl: '',
    apiBaseProxyUrl: 'https://local-cbt.link/api/proxy',
    logo: '/images/logo/local/logo.svg',
    watermark: '/images/logo/local/watermark.svg',
    header: localHeader,
    footer: localFooter,
  },

  production: {
    projectName: 'local',
    idp: 'local',
    clientBaseUrl: 'https://www.local-cbt.link/',
    loginPageUrl: 'https://local-cbt.link/login',
    logoutRedirectUri: 'https://local-cbt.link/api/auth/logout',
    apiBaseUrl: '',
    apiBaseProxyUrl: 'https://local-cbt.link/api/proxy',
    logo: '/images/logo/local/logo.svg',
    watermark: '/images/logo/local/watermark.svg',
    header: localHeader,
    footer: localFooter,
  },

  test: {
    projectName: 'local',
    idp: 'local',
    clientBaseUrl: 'https://www.local-cbt.link/',
    loginPageUrl: 'https://local-cbt.link/login',
    logoutRedirectUri: 'https://local-cbt.link/api/auth/logout',
    apiBaseUrl: '',
    apiBaseProxyUrl: 'https://local-cbt.link/api/proxy',
    logo: '/images/logo/local/logo.svg',
    watermark: '/images/logo/local/watermark.svg',
    header: localHeader,
    footer: localFooter,
  },
};
