import React from 'react';
import styled from 'styled-components';
import UserIcon from 'app.feature/cbt/asset/user_icon';

const UserProfile = ({ nickname }: any) => {
  return (
    <StyledWrapper>
      <div className="profile-wrapper">
        <UserIcon />
        <span>{nickname}님</span>
      </div>
    </StyledWrapper>
  );
};

export default UserProfile;

const StyledWrapper = styled.div`
  .profile-wrapper {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    span {
      margin-left: 10px;
      font-size: 20px;
      font-weight: 600;
    }
  }
`;
