import { useRouter } from 'next/router';
import { NavBarActiveKey } from 'src/app.core/linkareer/constant/navBarMenuItems';

export const useActiveCategory = () => {
  const { asPath } = useRouter();

  switch (asPath) {
    case '/exam/major-company': {
      return NavBarActiveKey.CBT_COMPANY;
    }
    case '/exam/public-company': {
      return NavBarActiveKey.CBT_PUBLIC;
    }
    case '/exam/psat': {
      return NavBarActiveKey.CBT_PSAT;
    }
    case '/exam/korean-history': {
      return NavBarActiveKey.CBT_KOREAN_HISTORY;
    }

    default: {
      return NavBarActiveKey.CBT;
    }
  }
};
