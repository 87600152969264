import React from 'react';
import styled from 'styled-components';

const Footer: React.FC = () => {
  return <StyledWrapper>로컬 푸터</StyledWrapper>;
};

export default Footer;

const StyledWrapper: any = styled.header`
  height: 100px;
  border-top: 1px solid #f5f5f5;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
`;
