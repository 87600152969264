import { useEffect, useState } from 'react'
import nookies from 'nookies'
import { CHAT_TOOLTIP_COOKIE } from './useChatCookies'

const useChatTooltipEffect = () => {
  const [isTooltipVisible, setIsTooltipVisible] = useState(false)

  useEffect(() => {
    const cookies = nookies.get(null)
    setIsTooltipVisible(!cookies[CHAT_TOOLTIP_COOKIE])
  }, [])

  return { isTooltipVisible, setIsTooltipVisible }
}

export default useChatTooltipEffect
