import { LINKAREER_COMMUNITY_DOMAIN, LINKAREER_DOMAIN } from 'src/app.core/linkareer/constant/urls';
import { UrlObject } from 'url';

export enum NavBarActiveKey {
  CHANNEL,
  CHANNEL_INTEREST,
  RECRUIT_HOME,
  COMMUNITY,
  INTERN,
  RECRUIT,
  ACTIVITY,
  CONTEST,
  EDUCATION,
  CLUB,
  CALENDAR,
  COVER_LETTER,
  SELF_INTRODUCTION,
  CBT,
  CBT_COMPANY,
  CBT_PUBLIC,
  CBT_PSAT,
  CBT_KOREAN_HISTORY,
  CAREER_MANAGEMENT,
}

export enum NavBarBadge {
  NONE,
  NEW,
  COUNT,
}

export interface NavMenuItemType {
  label: string;
  href: UrlObject;
  badge?: NavBarBadge;
  active: NavBarActiveKey;
}

export interface HeaderNavItemType extends NavMenuItemType {
  menuItems?: Array<NavMenuItemType>;
}

// URL 생성 함수
const createUrl = (domain: string, path: string, query?: Record<string, string>): UrlObject => {
  return { pathname: `https://${domain}${path}`, query };
};

// 메뉴 아이템 생성 함수
const createMenuItem = (
  label: string,
  active: NavBarActiveKey,
  href: UrlObject,
  badge?: NavBarBadge
): NavMenuItemType => {
  return {
    label,
    href,
    active,
    badge: badge ?? NavBarBadge.NONE,
  };
};

const createSubMenuItem = (
  label: string,
  active: NavBarActiveKey,
  path: string,
  query?: Record<string, string>
) => {
  const menuItem = createMenuItem(label, active, createUrl(LINKAREER_DOMAIN, path, query));

  return menuItem;
};

// 데스크탑 메뉴
export const NAV_BAR_MENU_ITEMS: Array<HeaderNavItemType> = [
  {
    label: '신입/인턴',
    href: createUrl(LINKAREER_DOMAIN, '/recruit-home'),
    active: NavBarActiveKey.RECRUIT_HOME,
    menuItems: [
      createSubMenuItem('홈', NavBarActiveKey.RECRUIT_HOME, '/recruit-home'),
      createSubMenuItem('인턴', NavBarActiveKey.INTERN, '/list/intern'),
      createSubMenuItem('신입', NavBarActiveKey.RECRUIT, '/list/recruit'),
      createSubMenuItem('공고달력', NavBarActiveKey.CALENDAR, '/calendar', {
        parentActiveTab: 'recurit',
      }),
    ],
  },
  {
    label: '대외활동/교육/공모전',
    active: NavBarActiveKey.ACTIVITY,
    href: createUrl(LINKAREER_DOMAIN, '/list/activity'),
    menuItems: [
      createSubMenuItem('대외활동', NavBarActiveKey.ACTIVITY, '/list/activity'),
      createSubMenuItem('교육', NavBarActiveKey.EDUCATION, '/list/education'),
      createSubMenuItem('공모전', NavBarActiveKey.CONTEST, '/list/contest'),
      createSubMenuItem('동아리', NavBarActiveKey.CLUB, '/list/club'),
      createSubMenuItem('공고달력', NavBarActiveKey.CALENDAR, '/calendar', {
        parentActiveTab: 'activity',
      }),
    ],
  },
  {
    label: 'CBT',
    active: NavBarActiveKey.CBT,
    href: { pathname: '/' },
    menuItems: [
      createMenuItem('홈', NavBarActiveKey.CBT, { pathname: '/' }),
      createMenuItem('대기업 CBT', NavBarActiveKey.CBT_COMPANY, {
        pathname: '/exam/major-company',
      }),
      createMenuItem('공기업 CBT', NavBarActiveKey.CBT_PUBLIC, {
        pathname: '/exam/public-company',
      }),
      createMenuItem('PSAT CBT', NavBarActiveKey.CBT_PSAT, {
        pathname: '/exam/psat',
      }),
      createMenuItem('한국사 CBT', NavBarActiveKey.CBT_KOREAN_HISTORY, {
        pathname: '/exam/korean-history',
      }),
    ],
  },
  {
    label: '커뮤니티',
    active: NavBarActiveKey.COMMUNITY,
    href: createUrl(LINKAREER_COMMUNITY_DOMAIN, '/community'),
    menuItems: [
      createMenuItem(
        '홈',
        NavBarActiveKey.COMMUNITY,
        createUrl(LINKAREER_COMMUNITY_DOMAIN, '/community')
      ),
    ],
  },
  {
    label: '자기소개서',
    href: createUrl(LINKAREER_DOMAIN, '/cover-letter/search'),
    active: NavBarActiveKey.COVER_LETTER,
    badge: NavBarBadge.COUNT,
    menuItems: [
      createSubMenuItem('자소서 만능 검색기', NavBarActiveKey.COVER_LETTER, '/cover-letter/search'),
      createSubMenuItem('자소서 작성', NavBarActiveKey.SELF_INTRODUCTION, '/self-introduction'),
    ],
  },
  {
    label: '멘토 게시판',
    active: NavBarActiveKey.COMMUNITY,
    href: createUrl(LINKAREER_COMMUNITY_DOMAIN, '/mentoring'),
    badge: NavBarBadge.NEW,
    menuItems: [
      createMenuItem(
        '취업/문과 고민',
        NavBarActiveKey.COMMUNITY,
        createUrl(LINKAREER_COMMUNITY_DOMAIN, '/mentoring')
      ),
      createMenuItem(
        '취업/이과 고민',
        NavBarActiveKey.COMMUNITY,
        createUrl(LINKAREER_COMMUNITY_DOMAIN, '/STEM_mentoring')
      ),
      createMenuItem(
        '인턴 고민',
        NavBarActiveKey.COMMUNITY,
        createUrl(LINKAREER_COMMUNITY_DOMAIN, '/mentor_intern')
      ),
      createMenuItem(
        '삼성 멘토질문방',
        NavBarActiveKey.COMMUNITY,
        createUrl(LINKAREER_COMMUNITY_DOMAIN, '/mento_samsung')
      ),
    ],
  },
  {
    label: '채널',
    href: createUrl(LINKAREER_DOMAIN, '/channel', { tab: 'home' }),
    active: NavBarActiveKey.CHANNEL,
    menuItems: [
      createSubMenuItem('홈', NavBarActiveKey.CHANNEL, '/channel', { tab: 'home' }),
      createSubMenuItem('내 관심 소식', NavBarActiveKey.CHANNEL_INTEREST, '/channel', {
        tab: 'interest',
      }),
    ],
  },
];

// 모바일 메뉴
export const MOBILE_MENU_ITEMS: Array<HeaderNavItemType> = [
  {
    label: '신입/인턴',
    href: createUrl(LINKAREER_DOMAIN, '/recruit-home'),
    active: NavBarActiveKey.RECRUIT_HOME,
    menuItems: [
      createSubMenuItem('홈', NavBarActiveKey.RECRUIT_HOME, '/recruit-home'),
      createSubMenuItem('인턴', NavBarActiveKey.INTERN, '/list/intern'),
      createSubMenuItem('신입', NavBarActiveKey.RECRUIT, '/list/recruit'),
      createSubMenuItem('공고달력', NavBarActiveKey.CALENDAR, '/calendar'),
    ],
  },
  {
    label: '대외활동/교육/공모전',
    active: NavBarActiveKey.ACTIVITY,
    href: createUrl(LINKAREER_DOMAIN, '/list/activity'),
    menuItems: [
      createSubMenuItem('대외활동', NavBarActiveKey.ACTIVITY, '/list/activity'),
      createSubMenuItem('교육', NavBarActiveKey.EDUCATION, '/list/education'),
      createSubMenuItem('공모전', NavBarActiveKey.CONTEST, '/list/contest'),
      createSubMenuItem('동아리', NavBarActiveKey.CLUB, '/list/club'),
    ],
  },
  {
    label: '채널',
    href: createUrl(LINKAREER_DOMAIN, '/channel', { tab: 'home' }),
    active: NavBarActiveKey.CHANNEL,
    menuItems: [
      createSubMenuItem('홈', NavBarActiveKey.CHANNEL, '/channel', { tab: 'home' }),
      createSubMenuItem('내 관심 소식', NavBarActiveKey.CHANNEL_INTEREST, '/channel', {
        tab: 'interest',
      }),
    ],
  },
  {
    label: 'CBT',
    active: NavBarActiveKey.CBT,
    href: { pathname: '/' },
    menuItems: [
      createMenuItem('홈', NavBarActiveKey.CBT, { pathname: '/' }),
      createMenuItem('대기업 CBT', NavBarActiveKey.CBT_COMPANY, {
        pathname: '/exam/major-company',
      }),
      createMenuItem('공기업 CBT', NavBarActiveKey.CBT_PUBLIC, {
        pathname: '/exam/public-company',
      }),
      createMenuItem('PSAT CBT', NavBarActiveKey.CBT_PSAT, {
        pathname: '/exam/psat',
      }),
      createMenuItem('한국사 CBT', NavBarActiveKey.CBT_KOREAN_HISTORY, {
        pathname: '/exam/korean-history',
      }),
    ],
  },
  {
    label: '커뮤니티',
    active: NavBarActiveKey.COMMUNITY,
    href: createUrl(LINKAREER_COMMUNITY_DOMAIN, '/community'),
    menuItems: [
      createMenuItem(
        '홈',
        NavBarActiveKey.COMMUNITY,
        createUrl(LINKAREER_COMMUNITY_DOMAIN, '/community')
      ),
    ],
  },
  {
    label: '자기소개서',
    href: createUrl(LINKAREER_DOMAIN, '/cover-letter/search'),
    active: NavBarActiveKey.COVER_LETTER,
    badge: NavBarBadge.COUNT,
    menuItems: [
      createSubMenuItem('자소서 만능 검색기', NavBarActiveKey.COVER_LETTER, '/cover-letter/search'),
      createSubMenuItem('자소서 작성', NavBarActiveKey.SELF_INTRODUCTION, '/self-introduction'),
    ],
  },
  {
    label: '멘토 게시판',
    active: NavBarActiveKey.COMMUNITY,
    href: createUrl(LINKAREER_COMMUNITY_DOMAIN, '/mentoring'),
    badge: NavBarBadge.NEW,
    menuItems: [
      createMenuItem(
        '취업/문과 고민',
        NavBarActiveKey.COMMUNITY,
        createUrl(LINKAREER_COMMUNITY_DOMAIN, '/mentoring')
      ),
      createMenuItem(
        '취업/이과 고민',
        NavBarActiveKey.COMMUNITY,
        createUrl(LINKAREER_COMMUNITY_DOMAIN, '/STEM_mentoring')
      ),
      createMenuItem(
        '인턴 고민',
        NavBarActiveKey.COMMUNITY,
        createUrl(LINKAREER_COMMUNITY_DOMAIN, '/mentor_intern')
      ),
      createMenuItem(
        '삼성 멘토질문방',
        NavBarActiveKey.COMMUNITY,
        createUrl(LINKAREER_COMMUNITY_DOMAIN, '/mento_samsung')
      ),
    ],
  },
];
