import { useEffect } from 'react';

/// /////////// Naver Analytics Config ////////////////
const NAVER_ANALYTICS_ID = '1cb6db32ba318f0';

const naverAnalyticsConfigScript = `
  try {
    if(!wcs_add) var wcs_add = {}; 
    wcs_add["wa"] = "${NAVER_ANALYTICS_ID}";
    wcs_do && wcs_do();
  } catch(e) {
  }
`;

// wcs.cnv("전환유형", "전환가치") 세팅.구매 유형인 경우 결제금액은 상황에 따라 변경될 수 있도록 변수가 들어가야 합니다. 그 외의 유형은 통상적으로 1의 값을 넣습니다.
const naverAnalyticsConfigScript2 = `
  try {
     console.log('log check');
            const _nasa = {};
            _nasa.cnv = wcs.cnv('1', 'totalPrice');
  } catch(e) {
  }
`;

/// /////////// Naver Analytics Config ////////////////

export default function useInitialMetaTag() {
  // Naver Analytics
  useEffect(() => {
    if (process.env.CBT_ENV !== 'production') {
      return () => {};
    }

    let script: HTMLScriptElement;
    let inlineScript: HTMLScriptElement;
    let inlineScript2: HTMLScriptElement;

    try {
      // 외부 스크립트 삽입
      script = document.createElement('script');
      script.src = 'https://wcs.naver.net/wcslog.js';
      script.async = true;
      document.body.appendChild(script);

      script.onerror = (error) => {
        console.error('Error loading Naver script:', error);
      };

      // 내부 스크립트 삽입
      inlineScript = document.createElement('script');
      inlineScript.innerHTML = naverAnalyticsConfigScript;
      document.body.appendChild(inlineScript);
      inlineScript.onerror = (error) => {
        console.error('Error loading Naver inline script:', error);
      };

      if (window.location.pathname === '/store/paymentSuccess') {
        inlineScript2 = document.createElement('script');
        inlineScript2.innerHTML = naverAnalyticsConfigScript2;
        document.body.appendChild(inlineScript2);

        inlineScript2.onerror = (error) => {
          console.error('Error loading Naver inline script 2:', error);
        };
      }
    } catch (e) {
      console.error('Error in Naver Analytics setup:', e);
    }

    return () => {
      if (script) {
        document.body.removeChild(script);
      }
      if (inlineScript) {
        document.body.removeChild(inlineScript);
      }
      if (inlineScript2) {
        document.body.removeChild(inlineScript2);
      }
    };
  }, []);
  // Naver Analytics End

  // google Tag
  useEffect(() => {
    if (process.env.CBT_ENV !== 'production') {
      return () => {};
    }

    let script: HTMLScriptElement;
    let inlineScript: HTMLScriptElement;
    let inlineScript2: HTMLScriptElement;
    let inlineScript3: HTMLScriptElement;

    try {
      // 외부 스크립트 삽입
      script = document.createElement('script');
      script.async = true;
      document.body.appendChild(script);

      script.onerror = (error) => {
        console.error('Error loading Google Tag Manager script:', error);
      };

      // 내부 스크립트 삽입
      inlineScript = document.createElement('script');
      inlineScript2 = document.createElement('script');
      inlineScript3 = document.createElement('script');

      document.body.appendChild(inlineScript);
      document.body.appendChild(inlineScript2);
      document.body.appendChild(inlineScript3);

      inlineScript.onerror = (error) => {
        console.error('Error loading Google Tag Manager inline script:', error);
      };
      inlineScript2.onerror = (error) => {
        console.error('Error loading Google Tag Manager inline script 2:', error);
      };
      inlineScript3.onerror = (error) => {
        console.error('Error loading Google Tag Manager inline script 3:', error);
      };
    } catch (e) {
      console.error('Error in Google Tag Manager setup:', e);
    }

    return () => {
      if (script) {
        document.body.removeChild(script);
      }
      if (inlineScript) {
        document.body.removeChild(inlineScript);
      }
      if (inlineScript2) {
        document.body.removeChild(inlineScript2);
      }
      if (inlineScript3) {
        document.body.removeChild(inlineScript3);
      }
    };
  }, []);
  // google Tag End

  return null;
}
