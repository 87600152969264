import React from 'react';
import {
  LinkareerHosts,
  LinkareerProject,
} from 'app.core/linkareer/constant/linkareerProjectConfig';
import { LocalProject } from 'app.core/local/constant/localProjectConfig';

type CBT_ENV = 'development' | 'production' | 'test';

const currentEnv: CBT_ENV = (process.env.CBT_ENV as CBT_ENV) || 'development';

export const hosts = ['local-cbt.link', 'cbt.local-cbt.link', ...LinkareerHosts] as const;
export type Host = (typeof hosts)[number];

export type ProjectConfig = {
  projectName: string;
  logoutRedirectUri: string;
  loginPageUrl: string;
  clientBaseUrl: string;
  apiBaseUrl: string;
  apiBaseProxyUrl: string;
  idp: string;
  header: React.FC;
  footer: React.FC;
  logo: string;
  watermark: string;
  kakaoServiceKey?: string;
};

type ProjectConfigs = {
  [key in Host]: ProjectConfig;
};

export const linkareerProjectConfigs: ProjectConfigs = {
  'cbt.linkareer.com': LinkareerProject[currentEnv] as ProjectConfig, // PRODUCTION
  'cbt.lnkr.cc': LinkareerProject[currentEnv] as ProjectConfig, // LOCAL
  'local-cbt.lnkr.cc': LinkareerProject[currentEnv] as ProjectConfig, // LOCAL
};

export const projectConfigs: ProjectConfigs = {
  // ProjectConfig
  'local-cbt.link': LocalProject[currentEnv] as ProjectConfig, // LOCAL
  'cbt.local-cbt.link': LocalProject[currentEnv] as ProjectConfig, // TEST
  ...linkareerProjectConfigs,
};
