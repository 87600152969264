import { FC } from 'react';
import { useCoverLetterCount } from 'src/app.core/linkareer/hook/useCoverLetterCount';
import SpeechBubbleIcon from 'src/app.core/linkareer/svgIcon/SpeechBubble';
import styled from 'styled-components';

const CoverLetterCount: FC = () => {
  const { coverLetterCount } = useCoverLetterCount();

  return (
    <StyledWrapper>
      <SpeechBubbleIcon className="cover-letter-icon" width={53} height={21} />
      <p role="tooltip" className="cover-letter-count">
        {coverLetterCount}건
      </p>
    </StyledWrapper>
  );
};

export default CoverLetterCount;

const StyledWrapper = styled.div`
  position: relative;

  .cover-letter-icon {
    position: absolute;
    top: -36px;
    right: 10px;
    width: 55px;
    height: 22px;
  }

  .cover-letter-count {
    width: 40px;
    height: 16px;
    font-size: 10px;
    font-weight: bold;
    line-height: 17px;
    letter-spacing: -0.44px;
    text-align: center;
    color: #ef2929;
    position: absolute;
    top: -36px;
    right: 18px;
  }
`;
