import { FC } from 'react';
import TSvgProps from '../types/svgPropsType';

const SpeechBubbleIcon: FC<TSvgProps> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="55"
      height="22"
      viewBox="0 0 55 22"
      aria-label="말풍선"
      {...props}
    >
      <desc>말풍선</desc>
      <g fill="#fff">
        <path
          d="M25.5 18.651c-.513 0-.985-.258-1.262-.69l-1.582-2.46H4A4.505 4.505 0 01-.5 11V4C-.5 1.519 1.519-.5 4-.5h42c2.481 0 4.5 2.019 4.5 4.5v7c0 2.481-2.019 4.5-4.5 4.5H28.344l-1.582 2.462a1.495 1.495 0 01-1.262.689z"
          transform="translate(2.5 2)"
        ></path>
        <path
          fill="#ef2929"
          d="M25.5 18.151a.991.991 0 00.841-.46l1.73-2.69H46A4 4 0 0050 11V4a4 4 0 00-4-4H4a4 4 0 00-4 4v7a4 4 0 004 4h18.93l1.73 2.691c.196.307.518.46.84.46m0 1a1.991 1.991 0 01-1.683-.92l-1.434-2.23H4A5.008 5.008 0 01-1 11V4c0-2.757 2.243-5 5-5h42c2.757 0 5 2.243 5 5v7c0 2.757-2.243 5-5 5H28.617l-1.435 2.232a1.99 1.99 0 01-1.682.919z"
          transform="translate(2.5 2)"
        ></path>
      </g>
      <path fill="none" d="M0 0h55v22H0z"></path>
    </svg>
  );
};

export default SpeechBubbleIcon;
