/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @next/next/inline-script-id */ /* eslint-disable camelcase */
/* eslint-disable @next/next/inline-script-id */
/* eslint-disable camelcase */

import {
  dehydrate,
  DehydratedState,
  Hydrate,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import App, { AppContext, AppProps } from 'next/app';
import { Nanum_Gothic, Noto_Sans_KR } from 'next/font/google';
import { useState } from 'react';
import { RecoilEnv, RecoilRoot } from 'recoil';

import useDehydratedRecoilState from 'app.hooks/useDehydratedRecoilState';
import AppWeb from 'app.layout/AppWeb';
import { theme } from 'app.styled/theme';
import { ThemeProvider } from 'styled-components';
import { REACT_QUERY_CONFIG } from 'utils/config';
import ErrorHelper from 'utils/error/ErrorHelper';
import ErrorBoundary from 'utils/etc/Boundary/ErrorBoundary';
import LoadingBoundary from 'utils/etc/Boundary/LoadingBoundary';
import useInitialScript from 'utils/hooks/useInitialScript';

import { globalContext } from 'lib/api/axios/context';
import UserApi from 'lib/api/users';
import 'react-quill/dist/quill.snow.css';
import 'swiper/css';
import 'swiper/swiper-bundle.css';
import '../styles/globals.css';
import 'react-quill/dist/quill.snow.css';
import ModalRoot from 'components/molecules/Common/Modal/ModalRoot';

// 기타 필요한 전역 스타일 임포트

/// ////////////////////////////////////////////////////////////////////////
// Next FONT LOAD //
const NotoSansKR = Noto_Sans_KR({
  preload: false,
  weight: ['100', '300', '400', '500', '700', '900'],
  variable: '--font-NotoSansKR',
  display: 'swap',
});

const NanumGothic = Nanum_Gothic({
  preload: false,
  variable: '--font-NanumGothic',
  weight: ['400', '700', '800'],
});
// Next FONT LOAD //
/// ////////////////////////////////////////////////////////////////////////

/// ////////////////////////////////////////////////////////////////////////
// Recoil Key 중복되는 버그 삭제
// RECOIL ERROR FIX //
RecoilEnv.RECOIL_DUPLICATE_ATOM_KEY_CHECKING_ENABLED = false;
// RECOIL ERROR FIX //
/// ////////////////////////////////////////////////////////////////////////

export type PageProps = {
  host: string;
  dehydratedState: any;
};

export interface CbtAppProps extends AppProps {
  dehydratedState?: DehydratedState;
  pageProps: PageProps;
}

function CbtApp({ Component, pageProps, dehydratedState }: CbtAppProps) {
  const [queryClient] = useState(() => new QueryClient(REACT_QUERY_CONFIG));
  const dehydratedRecoilState = useDehydratedRecoilState(pageProps);
  useInitialScript();

  const mergeDehydratedState = {
    mutations: [
      ...(pageProps?.dehydratedState?.mutations || []),
      ...(dehydratedState?.mutations || []),
    ],
    queries: [...(pageProps?.dehydratedState?.queries || []), ...(dehydratedState?.queries || [])],
  };

  return (
    <main className={`${NotoSansKR.variable} ${NanumGothic.variable}`}>
      <QueryClientProvider client={queryClient}>
        <ReactQueryDevtools initialIsOpen={false} />
        <ErrorBoundary
          errorFallback={(props) => <ErrorHelper {...props} />}
          refetch={() => window.location.reload()}
        >
          <LoadingBoundary>
            <Hydrate state={mergeDehydratedState}>
              <RecoilRoot initializeState={dehydratedRecoilState}>
                <ThemeProvider theme={theme}>
                  <ModalRoot />
                  <AppWeb Component={Component} pageProps={pageProps} />
                </ThemeProvider>
              </RecoilRoot>
            </Hydrate>
          </LoadingBoundary>
        </ErrorBoundary>
      </QueryClientProvider>
    </main>
  );
}

export default CbtApp;

CbtApp.getInitialProps = async (ctx: AppContext) => {
  const appProps = await App.getInitialProps(ctx);
  let currentHost = '';

  //Server Side Case
  if (ctx?.ctx.req) {
    currentHost = ctx?.ctx.req?.headers.host ?? '';
    globalContext.setCtx(ctx);
  }

  //Client Side Case
  if (typeof window !== 'undefined' && window.location?.host) {
    currentHost = window.location?.host;
  }

  const queryClient = new QueryClient();
  await queryClient.prefetchQuery(['/users/me'], () => UserApi.currentUser());
  const dehydratedState = dehydrate(queryClient);

  return {
    ...appProps,
    pageProps: {
      host: currentHost,
      ...appProps.pageProps,
    },
    dehydratedState,
  };
};
