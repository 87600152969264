import { ProjectConfig } from 'app.core/module/projectConfigs';
import HeaderLinkareer from 'app.core/linkareer/component/Header/HeaderLinkareer';
import FooterLinkareer from 'app.core/linkareer/component/Footer/FooterLinkareer';

export const LinkareerHosts = ['cbt.linkareer.com', 'cbt.lnkr.cc', 'local-cbt.lnkr.cc'];

export const LinkareerProject: { [key in 'production' | 'development' | 'test']: ProjectConfig } = {
  development: {
    projectName: 'Linkareer',
    clientBaseUrl: 'https://lnkr.cc/',
    loginPageUrl: 'https://lnkr.cc/login',
    logoutRedirectUri: 'https://gateway-api.lnkr.cc/logout',
    apiBaseUrl: 'https://gateway-api.lnkr.cc',
    apiBaseProxyUrl: 'https://api.lnkr.cc/academy',
    idp: 'linkareer',
    header: HeaderLinkareer,
    footer: FooterLinkareer,
    logo: '/images/logo/linkareer/logo.svg',
    kakaoServiceKey: '171d15c1a52fc8d0eb38550f93c1cbee', // 로컬 환경에서는 카카오 developer를 통한 공유하기가 잘못된 요청 팝업으로 동작됩니다
    watermark: '',
  },

  production: {
    projectName: 'Linkareer',
    clientBaseUrl: 'https://linkareer.com/',
    loginPageUrl: 'https://linkareer.com/login',
    logoutRedirectUri: 'https://api.linkareer.com/logout',
    apiBaseUrl: 'https://api.linkareer.com',
    apiBaseProxyUrl: 'https://api.linkareer.com',
    idp: 'linkareer',
    header: HeaderLinkareer,
    footer: FooterLinkareer,
    logo: '/images/logo/linkareer/logo.svg',
    kakaoServiceKey: '171d15c1a52fc8d0eb38550f93c1cbee',
    watermark: '',
  },

  test: {
    projectName: 'Linkareer',
    clientBaseUrl: 'https://lnkr.cc/',
    loginPageUrl: 'https://lnkr.cc/login',
    logoutRedirectUri: 'https://gateway-api.lnkr.cc/logout',
    apiBaseUrl: 'https://gateway-api.lnkr.cc',
    apiBaseProxyUrl: 'https://gateway-api.lnkr.cc',
    idp: 'linkareer',
    header: HeaderLinkareer,
    footer: FooterLinkareer,
    logo: '/images/logo/linkareer/logo.svg',
    kakaoServiceKey: '171d15c1a52fc8d0eb38550f93c1cbee',
    watermark: '',
  },
};
