import { ProjectConfig, projectConfigs } from './projectConfigs';
import { Host, hosts } from 'app.core/module/projectConfigs';

const isAllowedHost = (host?: string): host is Host => {
  if (!host) return false;
  return (hosts as readonly string[]).indexOf(host) > 0;
};

export const getProjectConfigByHost = (hostUrl?: string): ProjectConfig => {
  const host = isAllowedHost(hostUrl) ? hostUrl : 'local-cbt.link';

  return {
    ...projectConfigs[host],
  };
};
