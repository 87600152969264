import Head from 'next/head';
import { SnackbarProvider } from 'notistack';
import { Suspense } from 'react';
import styled from 'styled-components';
import { useCurrentUser } from '../../hook/useCurrentUser';
import { useIsUaMobile } from '../../hook/useIsUaMobile';
import CustomerMessageFormContainer from '../userCustomerService/CustomerMessageForm';
import { ChattingProvider } from './chat/module/ChattingContext';
import HeaderDesktop from './desktop/Header.desktop';
import HeaderMobile from './mobile/Header.mobile';
import NavBarMobile from './mobile/NavBar/NavBar.mobile';
import NavBarDesktop from './desktop/NavBar/NavBar.desktop';

const HeaderLinkareer = () => {
  const { isMobile } = useIsUaMobile();

  const { currentUser } = useCurrentUser();

  if (isMobile === null) return <StyledWrapper />;

  return (
    <>
      <Head>
        <script src="https://developers.kakao.com/sdk/js/kakao.min.js" async></script>
      </Head>
      <SnackbarProvider maxSnack={3} autoHideDuration={2000}>
        <ChattingProvider>
          <CustomerMessageFormContainer>
            <Suspense>
              <StyledWrapper data-device-mode={isMobile ? 'mobile' : 'desktop'}>
                {isMobile ? (
                  <>
                    <HeaderMobile
                      notificationsUnreadCount={currentUser?.notificationUnreadCount}
                      scrapCount={currentUser?.scrapCount}
                    />
                    <NavBarMobile />
                  </>
                ) : (
                  <>
                    <HeaderDesktop />
                    <NavBarDesktop userType={currentUser?.type} />
                  </>
                )}
              </StyledWrapper>
            </Suspense>
          </CustomerMessageFormContainer>
        </ChattingProvider>
      </SnackbarProvider>
    </>
  );
};

export default HeaderLinkareer;

const StyledWrapper = styled.header`
  width: 100%;
  position: sticky;
  z-index: 100;

  &[data-device-mode='mobile'] {
    height: 161px;
    margin-bottom: 0;
    top: -161px;
  }

  &[data-device-mode='desktop'] {
    height: 175px;
    top: -114px;
    margin-bottom: 96px;
  }

  a,
  p,
  span,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
    padding: 0;
  }

  * {
    box-sizing: inherit;
  }

  input,
  button,
  p,
  a,
  span {
    box-sizing: border-box;
  }

  ul,
  ol,
  li {
    list-style: none;
    padding: 0;
    margin: 0;
  }
`;
