import {
  ACTIVITY_BOT,
  AD_CONTACT,
  CONTEST_BOT,
  COVERLETTER_BOT,
  FB_CONTESTWHAT,
  FB_UNIVWHAT,
  FINANCIAL_REPORTS,
  INTRODUCE_LINKAREER,
  LINKAREER_POST,
  RECRUIT_BOT,
  SPECUPAD_JOBPOSTS,
  SPECUP_CAFE,
  LINKAREER_BLOG,
  LINKAREER_COMMUNITY_DOMAIN,
  LINKAREER_DOMAIN,
} from 'src/app.core/linkareer/constant/urls';
import { useOpenCustomerMessageForm } from '../../../userCustomerService/CustomerMessageForm';

type FooterItemType = Array<{
  title: string;
  items: Array<{
    label: string;
    href?: string;
    onClick?: () => void;
    target?: string;
  }>;
}>;

/**
 * @desc Footer의 항목,링크 정보를 반환하고 고객센터 팝업과 api를 관리하는 hook
 * @returns
 */
const useFooterItems = () => {
  const openCustomerMessageForm = useOpenCustomerMessageForm();

  const footerItems: FooterItemType = [
    {
      title: '링커리어',
      items: [
        { label: '링커리어 소개', href: INTRODUCE_LINKAREER },
        { label: '공지사항', href: `//${LINKAREER_COMMUNITY_DOMAIN}/notice` },
        { label: '채용', href: SPECUPAD_JOBPOSTS },
        { label: '회사 공지사항', href: FINANCIAL_REPORTS },
      ],
    },
    {
      title: '고객문의',
      items: [
        { label: '광고 및 제휴 문의', href: AD_CONTACT },
        { label: '고객문의', onClick: openCustomerMessageForm },
        { label: 'FAQ', href: `//${LINKAREER_DOMAIN}/faq/qna` }, // TODO : FAQ 링크 기획팀 확인 후 변경
      ],
    },
    {
      title: '이용안내',
      items: [
        { label: '이용약관', href: `https://${LINKAREER_DOMAIN}/terms` },
        { label: '개인정보처리방침', href: `https://${LINKAREER_DOMAIN}/privacy` },
        {
          label: '이용약관, 개인정보처리방침 변경 고지',
          href: 'https://timespread.notion.site/d65e8ba1fc2c443d80c69745327488af',
          target: '_blank',
        },
      ],
    },
    {
      title: '연관 서비스',
      items: [
        { label: '대외활동봇', href: ACTIVITY_BOT },
        { label: '공모전봇', href: CONTEST_BOT },
        { label: '채용봇', href: RECRUIT_BOT },
        { label: '자소서봇', href: COVERLETTER_BOT },
      ],
    },
    {
      title: '',
      items: [
        { label: '맞춤공고', href: `https://${LINKAREER_DOMAIN}/list/intern` },
        { label: '공고달력', href: `https://${LINKAREER_DOMAIN}/calendar` },
        { label: '블로그', href: LINKAREER_BLOG },
        { label: '포스트', href: LINKAREER_POST },
      ],
    },
    {
      title: '',
      items: [
        { label: '스펙업', href: SPECUP_CAFE },
        { label: '대외활동 뭐하지', href: FB_UNIVWHAT },
        { label: '공모전 뭐하지', href: FB_CONTESTWHAT },
      ],
    },
  ];
  return { footerItems };
};

export default useFooterItems;
