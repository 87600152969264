import dynamic from 'next/dynamic';
import Link from 'next/link';
import {
  HeaderNavItemType,
  NavBarActiveKey,
  NavBarBadge,
} from 'src/app.core/linkareer/constant/navBarMenuItems';
import styled from 'styled-components';
import { useActiveCategory } from '../../../hook/useActiveCategory';
import CoverLetterCount from './CoverLetterCount';
import NewBadge from './NewBadge';

interface NavMenuItemProps {
  menuInfo: HeaderNavItemType;
  activeKey: NavBarActiveKey;
  isShowNavbarDetail: boolean;
}

const NavBarHoverMenu = dynamic(() => import('./NavBarHoverMenu'), {
  ssr: false,
});

const NavMenuItem = ({ menuInfo, activeKey, isShowNavbarDetail }: NavMenuItemProps) => {
  const activeCategory = useActiveCategory();
  const activeTab = activeKey === NavBarActiveKey.CBT;

  return (
    <StyledWrapper>
      <Link href={menuInfo.href} className="menu-button" data-active={activeTab}>
        {menuInfo.label}
        {menuInfo.badge === NavBarBadge.NEW && <NewBadge />}
        {menuInfo.badge === NavBarBadge.COUNT && <CoverLetterCount />}
      </Link>
      {menuInfo.menuItems?.length && !isShowNavbarDetail && (
        <NavBarHoverMenu
          activeKey={activeCategory}
          menuItems={menuInfo.menuItems}
          isSelectedMainMenu={activeTab}
        />
      )}
    </StyledWrapper>
  );
};

export default NavMenuItem;

const StyledWrapper = styled.li`
  height: 60px;
  display: block;

  .menu-button {
    height: 60px;
    font-size: 17px;
    font-weight: bold;
    line-height: 60px;
    letter-spacing: -0.68px;
    text-align: left;
    color: #333333;
    display: flex;
    justify-content: center;
    align-items: center;

    &[data-active='true'] {
      color: #01a0ff;
      font-weight: bold;
    }

    &:hover {
      color: #01a0ff;
      background-color: transparent;
      font-weight: bold;
    }
  }

  .second-depth-nav-bar-hover {
    z-index: 10;
    display: none;
  }

  &:hover {
    .second-depth-nav-bar-hover {
      display: block;
      z-index: 11;
    }
  }
`;
