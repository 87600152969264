import React from 'react';
import theme from 'src/app.feature/cbt/constant/theme';

const UserIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40.002" viewBox="0 0 40 40.002">
      <g id="account_icon" transform="translate(0 -0.002)">
        <g
          id="Rectangle_2685"
          data-name="Rectangle 2685"
          transform="translate(0 0.002)"
          fill={theme.grey_dark}
          stroke="#fff"
          strokeWidth="0.5"
        >
          <rect width="40" height="40" rx="6" stroke="none" />
          <rect x="0.25" y="0.25" width="39.5" height="39.5" rx="5.75" fill="none" />
        </g>
        <path
          id="Path_1153"
          data-name="Path 1153"
          d="M58.325,37.648a5.89,5.89,0,1,1-5.889-5.889,5.889,5.889,0,0,1,5.889,5.889"
          transform="translate(-32.434 -23.997)"
          fill="#fff"
          fillRule="evenodd"
        />
        <path
          id="Intersection_1"
          data-name="Intersection 1"
          d="M16476,23584h28c0,9.092-6.273,16.463-14,16.463S16476,23593.092,16476,23584Z"
          transform="translate(16510.002 23624.004) rotate(180)"
          fill="#fff"
        />
      </g>
    </svg>
  );
};

export default UserIcon;
